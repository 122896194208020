<template>
  <v-layout>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list>

        <v-list-item link class="py-1" to="/customers/orders">
          <v-list-item-action>
            <v-icon>fa-shopping-cart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link class="py-1" to="/customers/bookings">
          <v-list-item-action>
            <v-icon>fa-ticket-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tours</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-divider></v-divider>

        <v-list-item link class="py-1" to="/customers/waivers">
          <v-list-item-action>
            <v-icon>fa-file-signature</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Waivers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        
      
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary" flat clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Lola Groups | Customer Portal | {{loggedUser.user.name}}</v-toolbar-title>
      <v-spacer />

      <v-menu right bottom :close-on-content-click="false" :close-on-click="true">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>fa-ellipsis-v</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="logout()">
            <v-list-item-action>
              <v-icon>fa-sign-out-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- END MENU BAR -->

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app color="grey lighten-3">
      <v-row>
        <v-col class="text-left  pb-0 pt-0">
          <span class="body-2">{{currentYear}} Lola Groups by Lolaguide Internet Inc.</span>
        </v-col>

        <v-col class="text-right pb-0 pt-0">
          <span class="caption">User: {{loggedUser.user.name}}</span>

          <span v-if="environment === 'DEVELOPMENT'" class="subtitle-2 ml-4">Environment: {{environment}} - version {{version}}</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-layout>
</template>

<script>
import environmentMixin from '@/mixins/environment-mixin.js'

export default {
  name: 'LayoutDashboardCustomer', 
  mixins: [environmentMixin], 
  data: function () {
    return {
      drawer: true, //menu open by default            
      currentYear: new Date().getFullYear()      
    }
  },
  computed: {
    loggedUser: function(){
        return this.$store.getters.loggedUser
    },
  },
  methods: {
    logout() {      
        this.$store.dispatch('logout')  
        this.$router.push('/login/customers')  
    }
  },
}
</script>